<template>
  <InputSearch v-model:value="search" @search="load()" placeholder="Поиск" style="max-width: 320px"/>
  <br><br>
  <Table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
         @change="handleTableChange"
         :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
         size="small" bordered rowKey="id"
         style="box-shadow: 0 5px 15px rgba(0,0,0,0.1); border-radius: 8px">
    <template #actions="{ record }">
      <Space v-if="$user.name && ($user.name).indexOf('(Legacy Light)') > -1">
        <Button @click="download(record.id, 'cert')">
          <template #icon>
            <DownloadOutlined/>
          </template>
          Документы
        </Button>
      </Space>
      <Space v-else>
        <Button @click="download(record.id, 'cert')">
          <template #icon>
            <DownloadOutlined/>
          </template>
          Сертификат
        </Button>
        <Button @click="download(record.id, 'annex')">
          <template #icon>
            <DownloadOutlined/>
          </template>
          <template v-if="record.data.inn">Заявление</template>
          <template v-else>Приложение</template>
        </Button>
        <template v-if="record.data.tech">
          <a class="ant-btn" v-if="$user.name && ($user.name).indexOf('Авангард') > -1" download target="_blank"
             :href="`/tech_avangard.pdf?t=${Date.now()}`">
            <DownloadOutlined/>
            Серт. тех. помощи</a>
          <a class="ant-btn"
             v-else-if="$user.name && (($user.name).indexOf('Шувалово') > -1 || ($user.name).indexOf('Автостиль') > -1)"
             download target="_blank"
             :href="`/tech_shuvalovo.pdf?t=${Date.now()}`">
            <DownloadOutlined/>
            Серт. тех. помощи</a>
          <a class="ant-btn" v-else download target="_blank" :href="`/tech_service1.pdf?t=${Date.now()}`">
            <DownloadOutlined/>
            Серт. тех. помощи</a>
        </template>
      </Space>
    </template>
  </Table>
</template>

<script setup>
import {Button, InputSearch, Space, Table} from "ant-design-vue";
import {DownloadOutlined} from "@ant-design/icons-vue";
import {inject, reactive, ref} from "vue";
import {useRoute} from "vue-router";

const $user = inject('$user')

const pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 15
})
const columns = [
  {
    title: 'Номер карты',
    dataIndex: 'data.card',
  },
  {
    title: ' Клиент',
    dataIndex: 'data.name',
  },
  {
    title: 'Дата сертификата',
    dataIndex: 'data.date',
  },
  {
    title: '',
    width: 310,
    slots: {customRender: 'actions'},
  },
];

const route = useRoute()
const search = ref('')
if (route.params.s) search.value = route.params.s

const data = ref([])
const loading = ref(false)
const load = async () => {
  loading.value = true
  let query = `page=${pagination.current}&s=` + search.value
  await $axios.get('docs?' + query).then(r => {
    data.value = r.data.data
    pagination.total = r.data.total
    pagination.pageSize = r.data.per_page
  }).catch(() => {
  })
  loading.value = false
}
load()

const handleTableChange = (pag, filters) => {
  pagination.current = pag.current
  load()
};

const download = async (id, type) => {
  await $axios.get('docs/' + id + '/' + type).then(r => {
    let link = document.createElement('a')
    link.href = r.data
    link.dispatchEvent(new MouseEvent('click'))
  }).catch(() => {
  })
}

</script>

<style>
.table-striped td {
  background-color: rgb(253, 253, 253);
}
</style>
